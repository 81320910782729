import styles from "./styles.module.scss";
import { cardData } from "../../Widgets/mock";
import classNames from "classnames";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { Coordinate } from "recharts/types/util/types";
import { getAdsLabel } from "@components/CheckWidget";

interface CustomTooltipProps {
  payload: Payload<ValueType, NameType>[];
  active: boolean;
  label: string;
  coordinate: Partial<Coordinate>;
  chartWidth: number;
}

const convertValue = (item: Payload<ValueType, NameType>) => {
  switch (item.name) {
    case "sales":
    case "spend":
      return `$${Math.floor(Number(item.value)).toLocaleString("en-US")}`;
    case "impressions":
    case "orders":
      return item.value.toLocaleString("en-US");
    case "ctr":
    case "cvr":
    case "acos":
    case "roas":
      return `${Number(item.value).toFixed(2)}%`;
    case "cpr":
    case "cpc":
      return `$${Number(item.value).toFixed(2)}`;
    default:
      return item.value.toLocaleString("en-US");
  }
};

export const CustomTooltip = ({
  active,
  payload,
  label,
  coordinate,
  chartWidth,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    const isLeft = coordinate.x - 200 > chartWidth / 2;
    return (
      <div className={classNames(styles.tooltip, isLeft && styles.left)}>
        <p className={styles.tooltip__label}>{label}</p>
        <ul className={styles.tooltip__list}>
          {payload.map((item, index) => (
            <>
              <li
                key={index}
                className={classNames(
                  styles.tooltip__item,
                  item.name.toString().length <= 4 &&
                    styles.tooltip__item__small,
                )}
              >
                <span
                  className={styles.dot}
                  style={{ backgroundColor: cardData[index].color }}
                ></span>
                <span className={styles.tooltip__name}>
                  {getAdsLabel(item.name as string)}
                  {item.name === "unitSolds" ? "Units Sold" : item.name}:{" "}
                </span>
                <span className={styles.tooltip__value}>
                  {convertValue(item)}
                </span>
              </li>
            </>
          ))}
        </ul>
      </div>
    );
  }

  return null;
};
